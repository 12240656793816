<template>
  <div class="layout-full-page">
    <div class="container">
      <div class="row justify-content-center py-5 py-md-8">
        <div class="col-12">
          <a class="d-flex justify-content-center" :href="absoluteUrl()">
            <img  width="120" :src="absoluteUrl('/images/logos/logo-dark.svg')" :alt="$store.state.appName" class="navbar-brand-img">
          </a>
        </div>
      </div>
    </div>
    <div style="min-height: 80vh;padding-bottom:80px;">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  components:{
  },
}
</script>
